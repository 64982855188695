/* eslint-disable */ 
<!-- Mto Presupuestos Funerarias Sucursales -->

<template>
  <div class="exp_pres_M" v-if="schema">
    <base_Header
      v-if="Entorno.header.header"
      :Entorno="Entorno.header"
      @onEvent="event_Header"
    >
    </base_Header>
   
    <v-sheet class="contenedor" :elevation="4">

        <!-- botoneras -->
        <!-- <div class="conflex" style="width:790px;margin-bottom:5px"> -->
          <div class="conflex" style="width:1100px;margin-bottom:5px">

        <!-- btra Mto -->
     
          <btramto class="conflex" style="width:230px;"
            :btra_permisos="schema.btra"
            :btra_particular="Entorno.btra"
            btra_modulo="m"
            :estado="estado"
            @onEvent="event_btra_Mto">
          </btramto>
   

       <!-- extra -->
          <div class="conflex" style="width:230px;margin-left:50px">
              <btraextra class="conflex"
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="standard"
                @onEvent="event_btra_Extra">
              </btraextra>
          </div>
    <!-- plantillas -->
       
            <v-select
              style="flex:0 0 240px;white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;"
                dense
                @change="changePlantilla()"
                v-model="plantillaValue"
              v-bind="$select"
              label="Plantillas"
              :items="itemsPlantilla"
              :disabled="plantillaDisabled"
              item-value="id"
              item-text="name">            
            </v-select>
        <!-- botones comunes -->
          <div class="conflex" style="width: 180px;justify-content:space-between;margin-left:53px">

                    <!-- <v-btn fab depressed x-small color="blue-grey lighten-4"
                       @click="comunicados()">
                       <v-icon> mdi-file-send-outline </v-icon>
                    </v-btn> -->
                    <!-- Ventana Documentos Expedientes -->
                    <!-- <exp_Docs
                      :padre="stName"
                      :id="expte.record.id">
                    </exp_Docs> -->
                    <exp_Docs                      
                      :id="expte.record.id">
                    </exp_Docs>

                    <!-- Ventana Comunicados Expedientes -->
                    <!-- <exp_Comunicados
                      :padre="stName"
                      :id="expte.record.id"
                      :accionRow="expte.record">
                    </exp_Comunicados>  -->

                    <v-btn fab depressed x-small color="blue-grey lighten-4"
                       @click="pdf()">
                       <v-icon> mdi-file-pdf-outline </v-icon>
                    </v-btn>            

                    <!-- <v-btn fab depressed x-small color="blue-grey lighten-4"
                       @click="docs()">
                       <v-icon> mdi-file-image-outline </v-icon>
                    </v-btn> -->

                    <v-btn fab depressed x-small color="blue-grey lighten-4"
                       @click="copy()"> copy
                    </v-btn>

                    <v-btn fab depressed x-small color="blue-grey lighten-4"
                       :disabled="!is_edit"
                       @click="pega()"> pega
                    </v-btn>
          </div>
     <!-- fin botonera comun -->
     </div>
     

      <!-- fila 1 -->
      <div class="conflex" style="width: 1180px">
        <!-- fila 1 columna 1 -->
        <div class="columna" style="flex: 0 1 750px">
          <div class="conflex" style="justify-content: space-between">
            <div class="cab">DATOS PRESUPUESTO</div>
            <div style="color: steelblue; padding-right: 20px">
                <!-- ReOpen -->     
                <!-- {{ accionRow.fra_conta_fh }}
                {{ accionRow.idRR }} -->
                <button @dblclick="boton_editar" 
                  v-if="(accionRow.fra_conta_fh>'2021' || accionRow.fra_conta_fh=='') && accionRow.idRR==0 && this.ndep>=6 && this.app==1" 
                  style="text-align:right;color:maroon">
                  <i> {{ accionRow.id }} - {{ accionRow.fra_conta_asi }}</i>
                </button>                
              <span v-else>{{ accionRow.id }} </span>
            </div>
          </div>
          <v-sheet v-bind="$cfg.styles.marco" style="width: 750px">
            <div class="conflex">
              <vllabel
                style="flex: 0 1 25%"
                label="EXPED"
                stilot="font-weight:bold;color:steelblue;text-align:center;  "
                :txt="expte.record.control"
              >
              </vllabel>
              <vllabel
                style="flex: 0 1 75%"
                label="Fallecido"
                stilot="font-weight:bold;color:steelblue;  "
                :txt="expte.record.fallename"
              >
              </vllabel>
            </div>
            <div class="conflex">
              <compMultiple     
                    style="flex: 1 1"            
                    :ctrl="schema.ctrls.cta_id"
                    :schema="schema"
                    :fn="changeCuenta"
                    :disabled="!is_edit">
              </compMultiple>

              <!-- View InformaciC3n de la Cuenta -->
              <div v-if="app==1">
                <!-- <info_cta_view
                  :padre="stName"
                  :tipo_cta="schema.ctrls.tipo_cta.value? schema.ctrls.tipo_cta.value : 0"
                  :cta_id="schema.ctrls.cta_id.value? schema.ctrls.cta_id.value : 0">                        
                </info_cta_view> -->
                <info_cta_view                 
                  :tipo_cta="schema.ctrls.tipo_cta.value? schema.ctrls.tipo_cta.value : 0"
                  :cta_id="schema.ctrls.cta_id.value? schema.ctrls.cta_id.value : 0">                        
                </info_cta_view>
              </div>

            <div v-if="schema.ctrls.pdi.value=='1'" style="font-weight:bold;font-size:1.5rem;color:red" > PDI </div>
            </div>
            <!-- fin linea -->
            <vlinput label="DESCRIP"
                    v-model="schema.ctrls.descrip.value"
                    :disabled="!is_edit">
            </vlinput>
            <div class="conflex" style="flex: 1 1 750px">
              <v-select
                style="text-align: center; flex: 0 1 100px"
                v-model="schema.ctrls.rt.value"
                v-bind="$select"
                label="Tipo I."
                :items="this.$store.state.datos_iniciales.rTribut"
                :disabled="!is_edit"
                item-value="id"
                item-text="name"
                @change="changeRt"
              ></v-select>

              <v-select
                style="flex: 0 1 100px"
                v-model="schema.ctrls.iva_general.value"
                v-bind="$select"
                label="% Gral"
                :items="get_ivas"
                :disabled="!is_edit"
                item-value="value"
                @change="changeIvaGeneral"
              ></v-select>

              <pres_sumas style="flex: 1" :pdat="schema.ctrls" />
            </div>

            <v-textarea
              v-bind="$textarea"
              v-model="schema.ctrls.obs_fra.value"
              :label="schema.ctrls.obs_fra.label"
              no-resize
              rows="1"
              :disabled="!is_edit">                  
            </v-textarea>
          </v-sheet>
        </div>



  <!-- fila 1 columna 2 -->
        <div class="columna" style="width: 240px">
          <div class="conflex" style="align-items: baseline;">
              <div class="cab" style="width:80px;margin-right:4px;">
                                
                <button @dblclick="cambio_tipo" 
                  v-if="(accionRow.fra_conta_asi==0 || (accionRow.idRR==0 && accionRow.fra_fh>'2021'))
                          && accionRow.tipo_ope=='C'
                          && this.ndep>=6 && is_edit
                          && this.app==1" 
                  style="text-align:right;color:yellow">
                  <i> * TIPO *</i>
                </button>
                <span v-else>TIPO</span>

              </div>
              <div :style="tipo_ope.style+';padding:0'">{{tipo_ope.text}}</div>
          </div>
          <v-sheet v-bind="$cfg.styles.marco">
             <!-- <div :style="tipo_ope.style">{{tipo_ope.text}} </div> -->
          
              <!--          
          <v-radio-group
            v-model="schema.ctrls.tipo_ope.value"
            row
            dense
            :ripple="false"
          >
            <v-radio label="COMPRA" value="C" color="green"></v-radio>
            <v-radio label="VENTA" value="V"></v-radio>
           </v-radio-group> -->

          <vlinput
            label="N.FRA "
            stilot="font-weight:bold;color:steelblue"
            stilol="flex:0 60px"
            v-model="schema.ctrls.fra_doc.value"
            :disabled="(is_edit==false || rol!='gestion' || schema.ctrls.tipo_ope.value!='C')"
          >
          </vlinput>

          <vlinput
            v-model="schema.ctrls.fra_fh.value"
            label="FECHA"
            type="date"
            stilol="flex:0 60px"
            :disabled="(is_edit==false || rol!='gestion' || schema.ctrls.tipo_ope.value!='C')"
           
          >
          </vlinput>
          <div class="conflex" style="justify-content: space-between">
            <!-- <v-checkbox
              style="margin-left: 0px"
              v-model="schema.ctrls.tipo_carabon.value"
              label="Cargo/Abono"
              false-value="0"
              true-value="1"
            >
            </v-checkbox> -->

           
            <select class="select"
              :disabled="(!is_edit || rol!='gestion')"
              @change="changeTipoCargoAbono"    
               v-model="schema.ctrls.rel_tp.value">
                <option
                  v-for="item in itemsTipoCargo"
                  :key="item.value"
                  :value="item.value">                
                    {{item.text}}
                </option>
              </select>
           
            </div>
            <div v-if="schema.ctrls.rel_tp.value>0">
            <vlinput
              label="D/FR."
              stilol="flex:0 60px"
              :disabled="(!is_edit || rol!='gestion')"
              v-model="schema.ctrls.relacion.value"
            >
            </vlinput>
            </div>

         
            <!-- <vlinput
              v-model="schema.ctrls.exp_id.value"
              stilol="flex:0 60px"
              type="date"
              label="FECHA"
            
              :disabled="!is_edit"
            >
            </vlinput> -->
            
          </v-sheet >

          
            <v-sheet v-bind="$cfg.styles.marco" v-if="app==1">
              <div>                          
                <vllabel v-show="accionRow.tipo_fra=='V'"
                  style="flex: 0 0 100%"
                  label="Emitida"
                  :txt="accionRow.fra_emision | fechahr">              
                </vllabel>                              
                <vllabel
                  style="flex: 0 0 100%"
                  label="Contabilizada"
                  :txt="accionRow.fra_conta_fh | fechahr">
                </vllabel>                                  
                <vllabel
                  style="flex: 0 0 100%" 
                  label="Asiento"
                  :txt="accionRow.fra_conta_asi">              
                </vllabel>                
              </div>
            </v-sheet>

        
          <!-- fin columna 2 -->
        </div>

            <div class="columna">
               <div  class="conflex">
              <v-icon large :color="viewBloqueada[Number(schema.ctrls.bloqueada.value)||0].color ">>
                      {{viewBloqueada[Number(schema.ctrls.bloqueada.value)||0].icon}}
              </v-icon>
            
             <v-icon large :color="viewAceptada[Number(schema.ctrls.aceptada.value)||0].color">
                      {{viewAceptada[Number(schema.ctrls.aceptada.value)||0].icon}}
              </v-icon> 
            </div>
     
          <!-- fin columna 3 -->
          </div>
        </div>


      <!-- fila 2 -->
      <div class="fila2 conflex">
        <!-- fila2 columna 1-->
        <div class="columna" style="padding-right: 8px">

         <!-- pagos directos asociados -->
  
         <div v-if="pres_import_directos.length">
         <div class="cab" style="width:750px">PRESUPUESTOS RELACIONADOS CON EL PRESUPUESTO IMPORTADO</div>
          <v-sheet :elevation="4">
         
                  <div v-for="(directo, index) in pres_import_directos" :key="index">
                       <div class="conflex" style="margin-left:55px">
                          <div style="width:400px"> {{directo.name_prod_id+"   "+directo.namea}}</div>
                          <div style="width:100px"> {{directo.imp}}€</div>
                          <div style="width:100px"> {{directo.sp?'SUPLIDO':''}}</div>
                       </div>
                  </div>   
                   <v-btn depressed x-small color="blue-grey lighten-4" style="margin: 10px 0 10px 55px"
                       @click="generarPresupuestos()"> Generar presupuestos relacionadoss
                    
                    </v-btn>
            
          </v-sheet>
          </div>
         
          <!-- detalle del presupuesto -->

          <!-- v-if="$store.state[stName].record" -->
          <exp_pres_detalle
            :padre="stName"
            :id="$store.state[stName].record.id"
            :disparoFinder="disparoFinder"
            @onEvent="event_btra_Mto"
            @onEventOrden="eventOrden">
          >
          </exp_pres_detalle>
        </div>
        <!-- fin fila2 columna 1 -->

        <!-- fila2 columna 2 -->
         <div v-if="!is_mxEdit"
             class="columna"
             style="width: 420px">

           <div v-if="schema.ctrls.tipo_ope.value=='V'">
            <!-- otros conceptos -->
            <div class="cab" style="width:125px">
            OTROS CONCEPTOS
          </div>
          <v-sheet v-bind="$cfg.styles.marco">
            <div class="conflex">
            <v-checkbox  style="width:20%;margin-right:0px"
                  
                  v-model="apd_computed"
                  :disabled="!is_edit"
                >
              </v-checkbox>
            
              <vllabel style="width:80%;margin-left:0px"
              label="APD"
              stilol="flex: 0 0 50px"
              stilot="text-align:right"
              :txt="schema.ctrls.tmt3.value|num(2)"
              :disabled="!is_edit"
            ></vllabel>
        </div>
        <div class="conflex">
            <v-input_n style="width:50%"
             @blur="calculo_apd_manual()"
              v-bind="$input"
              v-model="schema.ctrls.apd1.value"
              label="APD 1"
              :disabled="!is_edit"
            ></v-input_n>

              <v-input_n style="width:50%"
               @blur="calculo_apd_manual()"
              v-bind="$input"
              v-model="schema.ctrls.apd2.value"
              label="APD 2"
              :disabled="!is_edit"
            ></v-input_n>
        </div>
        <v-divider style="margin:4px 0"></v-divider>
          <!-- tramitacion -->
        <div class="conflex">
            <v-input_n style="width:50%"
              @blur="calculo()"
              v-model="schema.ctrls.tmt1.value"
              label="TRAMITACIÓN"
               v-bind="$input"
              :disabled="!is_edit"
            ></v-input_n>

              <v-input_n style="width:50%"
               @blur="calculo()"
              v-bind="$input"
              v-model="schema.ctrls.tmtpor1.value"
              simbolo="%"
              label="%"
              :disabled="!is_edit"
            ></v-input_n>
        </div>
        <!-- otros paises -->
          <div class="conflex">
            <v-input_n style="width:50%"
             @blur="calculo()"
              v-bind="$input"
              v-model="schema.ctrls.tmt2.value"
              label="OTR PAISES"
              :disabled="!is_edit"
            ></v-input_n>

              <v-input_n style="width:50%"
               @blur="calculo()"
              v-bind="$input"
              v-model="schema.ctrls.tmtpor2.value"
              simbolo="%"
              label="%"
              :disabled="!is_edit"
            ></v-input_n>
        </div>
        <!-- varios -->
        <div class="conflex">
            <v-input_n style="width:50%"
             @blur="calculo()"
              v-bind="$input"
              v-model="schema.ctrls.tmt4.value"
              label="VARIOS"
              :disabled="!is_edit"
            ></v-input_n>

              <v-input_n style="width:50%"
               @blur="calculo()"
              v-bind="$input"
              v-model="schema.ctrls.tmtpor4.value"
              simbolo="%"
              label="%"
              :disabled="!is_edit"
            ></v-input_n>
        </div>            
             <vllabel
              label="TRAMITACION"
              :txt="schema.ctrls.tmt.value | num(2)"
              stilot="text-align:right"
              stilol="text-align:center;flex:1 1 30%"
            >
            </vllabel>
            <vllabel
              label="IMP S/TRAM"
              :txt="schema.ctrls.impstmt.value | num(2)"
              stilot="text-align:right"
              stilol="text-align:center;flex:1 1 30%"
            >
            </vllabel>
        </v-sheet>
      </div>
       <!-- fin if compras/ventas -->




       <pres_iva_desglose
                :pdat="schema.ctrls"
                :is_edit="is_edit"
              >
        </pres_iva_desglose>

        <div class="conflex px-2">
          <v-input_n style="width:10%"
            @blur="calculo()"
            v-bind="$input"
            v-model="schema.ctrls.irpfpor.value"
            simbolo="%"
            label="% IRPF"
            :disabled="!is_edit"
          ></v-input_n>
          <vtlabel style="flex:1 1 20%"
            label="I.R.P.F"
            :txt="schema.ctrls.irpf.value | num(2)"
            stilot="text-align:right"
          />                                    
          </div>

        <pres_claves   :Aimp="clavesAc" :T="clavesTotal"> </pres_claves>
      
      </div>

     </div>
      <!-- fin bloque -->

    </v-sheet>
      <!-- ventana modal IMPORTACION PRESUPUESTOS -->      
      <v-dialog
        v-model="modal" persistent>
          <div class="centrado">             
            <component                 
                :is="componente_dinamico"
                :items-per-page="100"
                :id="schema.ctrls.cta_id.value"
                :filtroImportServicio="filtroImportServicio"
                :disparoFinder="disparo_funes_pres_import"
                @onEvent="imp_prestos_extraer">               
            </component>           
          </div>
      </v-dialog>  

  </div>
</template>

<script>
import { mixinMto } from "@/mixins/mixinMto.js";
import { mixinMD } from "@/mixins/mixinMD_maub.js";
// import { mixinCalculos } from "@/mixins/mixinCalculos.js";
import { funes_pres_calculo } from "@/mixins/funes_pres_calculo.js";
import { calculo_claves } from "@/mixins/calculo_claves.js";
import { mixinPresPack } from "@/mixins/mixinPresPack.js";
import plugs from "@/common/general_plugs";
const base_Header = () => plugs.groute("base_Header.vue", "base");
const btramto = () => plugs.groute("btramto_new.vue", "comp");
const btraextra = () => plugs.groute("btraextra_new.vue", "comp");
// const compfecha = () => plugs.groute("compfecha.vue", "comp");
// const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
const exp_pres_detalle = () => plugs.groute("exp_pres_detalle.vue", "comp");
const pres_claves = () => plugs.groute("pres_claves.vue", "comp");
const pres_sumas = () => plugs.groute("pres_sumas.vue", "comp");
const pres_iva_desglose = () => plugs.groute("pres_iva_desglose.vue", "comp");
const compMultiple = () => plugs.groute("compMultiple.vue", "comp");
const funes_pres_import_F = () => plugs.groute("funes_pres_import_F.vue", "comp");
const exp_Docs = () => plugs.groute("exp_Docs.vue", "comp");
const info_cta_view = () => plugs.groute("info_cta_view.vue", "comp");
// const exp_Comunicados = () => plugs.groute("exp_Comunicados.vue", "comp"); 

// const pres_total_cia = () => plugs.groute("pres_total_cia.vue", "comp");
// const pres_total_compra = () => plugs.groute("pres_total_compra.vue", "comp");

export default {
  mixins: [mixinMto, mixinMD, funes_pres_calculo, calculo_claves, mixinPresPack],
  components: {
    base_Header,
    btramto,
    btraextra,
    // compfecha,
    // ctrlfinder,
    exp_pres_detalle,
    pres_claves,
    pres_sumas,
    pres_iva_desglose,
    compMultiple,
    funes_pres_import_F,
    exp_Docs,
    info_cta_view,
    // exp_Comunicados
    // pres_total_compra,
  

  },

  props: {
    padre: { type: String, default: "" },
    accion: { type: [Number, String], default: "" },
    accionRow: { type: Object, default: () => {} },
    disparo: { type: Boolean, default: false },
    componenteTipo: { type: String, default: "M" },
    Entorno: {
      type: Object,
      default: function () {
        return JSON.parse(JSON.stringify(this.$cfg.base.M));
      },
    },
    datos_complementarios:{},
    auxRecord: { type: Object, default: null },
  },

  data() {
    return {
      schema: null,
      api: "exp_pres_M",
      stName: "stMexp_pres_M",
      stNameHijo: "stFexp_presLin_F",
      mtos_auxiliares: [
          { store:'stMexp_presLin_M', msg:'Tiene una linea de producto en edición. Guarde o Cancele antes de continuar'},
        ],
      // Pendiente: ver donde ponemos campo_relacionado
      campo_relacionado: "exp_id",
      tipo_detalle: "fras",
      disparoFinder: false,

      //añadido edicion manual
      edita: false,

      //importacion de presupuesto
      modal:false,
      disparo_funes_pres_import:false,
      componente_dinamico:null,
      pres_import_id:0, // guardo id de presuspuesto importado
      pres_import_directos:[], // guardo lineas de p.externos del pres importado
      //filtro del finder de importacion (cta_id, o lo que sea..)
      filtroImportServicio:{},
      //

      //
      // nuevo presupuesto 
      sw_nuevoInterno:false,
      datos_complementarios_internos:{},
     
      //producto: null, // pendiente: no creo que se use
      fila_copiada: null,
      pservicios: null,
      lineas_actualizadas: false,

      records_detalle: null,
      schema_detalle: null,
      itemsTipo_ope:[
        { tipo_ope:"", text:"ERROR TIPO PRESUPUESTO", style:"font-weight:bold;color:steelblue"},
        // { tipo_ope:"C", text:"COMPRA",  style:"font-weight:bold;font-size:.9rem;color:CornflowerBlue"},
        { tipo_ope:"C", text:"COMPRA",  style:"font-weight:bold;font-size:1rem;color:orange;"},
        { tipo_ope:"V", text:"VENTA",   style:"font-weight:bold;font-size:1rem;color:green;"},
        { tipo_ope:"SUP", text:"SUPLIDO",   style:"font-weight:bold;font-size:1rem;color:blue;"},
        { tipo_ope:"W", text:"VENTA TRAMITACIÓN",   style:"font-weight:bold;font-size:.9;color:rgb(0,255,0);"}
        ],
      itemsTipoCargo: [
        { value: 0, text: "Seleccione Cargo/ABono"},
        { value: 1, text: "PARCIAL" },
        { value: 2, text: "NULO" },
        { value: 3, text: "TOTAL" }
      ],
      // Productos con IVA 10% FLORES
       iva10:[12,21,52],
      //
      itemsCias: [
        { id: "83", name: "MAPFRE" },
        { id: "50165", name: "KUTXABANK" },
      ],
  
      // itemsPrestacion: [],
      // noduplicar_tipos:[10, 30, 18, 38, 36, 16, 17, 37],
      // municipio_required: [10, 11, 30, 31, 18, 38, 36, 16, 17, 37],
      // apd_tipos: [10, 11, 30, 31, 18, 38],
      apd_cias: [50165, 50508],
      itemsPlantilla:[{id:'4', name:'Servicio Incineración'},
                     {id:'3', name:'Servicio Inhumacion'},
                     {id:'5', name:'Servicio traslado'},
                     {id:'6', name:'Servicio recepcion/terminación'}
      ],
      plantillaValue:'0',
      plantillaDisabled:true,
      viewAceptada:[
        {icon:"mdi-check-bold", color:"grey"},
        {icon:"mdi-check-bold", color:"green"},
        {icon:"mdi-close",     color:"red"}
      ],
       viewBloqueada:[
        {icon:"mdi-lock-open", color:"green"},
        {icon:"mdi-lock",      color:"red"}
      ]
    };
  },

  methods: {
    // inicializo hijo antes cargar datos
    before_ini_component() {      
      if (!this.$store.state[this.stName]) return; 
      if (!this.$store.state[this.stName].hijo) return
      this.$store.commit(this.stName + '/data2State', { prop:'hijo', value:this.stNameHijo });
    },

    eventOrden(evt){
      
       let item={};
      if (evt.item) item=evt.item;
      if (item=={}) return;
      const hijo=this.$store.state[this.stName].hijo
      let arrayRecords=JSON.parse(JSON.stringify(this.$store.state[hijo].records));
      //encuentro el index del item en la array
      let index = arrayRecords.findIndex(e => e.id == item.id);
      //
      
      if (evt.accion=='down'){
          if (index !== -1 && index < arrayRecords.length - 1) {
                item = arrayRecords[index];
                arrayRecords[index] = arrayRecords[index + 1];
                arrayRecords[index + 1] = item;
          }
      }
      if (evt.accion=='up'){
        if (index > 0) {
          item= arrayRecords[index];
          arrayRecords[index] = arrayRecords[index - 1];
          arrayRecords[index - 1] = item;
        }
      }
        arrayRecords=arrayRecords.map((e,index) => {e.orden=index; return e});
        this.$store.commit('data2State', { modulo:hijo, prop:'records', value:arrayRecords});
        this.detalle_modificado(this.$store.state[hijo]);
    },

    ini_data() {
      console.log("DEV " + this.api + " ********************** (ini_data)");

      // guardo del store en variables locales
      this.schema = this.$store.state[this.stName].schema;

      // configuro Entorno
      this.Entorno.header.titulo = "Presupuestos/Facturas";
      this.Entorno.header.style += ";min-width:60rem";
      //this.Entorno_presup_detalle_F=JSON.parse(JSON.stringify(this.$cfg.base.D));
      // btra mantenimiento
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo.standardM));
        this.Entorno.btra.footer.extra = [   
         
          { accion: "acepto", icono: "mdi-check-bold", show: 1 },
          { accion: "bloqueo", icono: "mdi-lock", show: 1 },
          { accion: "pasoVenta", texto:"V", view:false,disabled:true,styT:'color:green;font-weight:bold;font-size:1rem;',  show: 1 },
          { accion: "pasoFactura", texto:"F", view:false,disabled:true,styT:'color:green;font-weight:bold;font-size:1rem;',  show: 1 },
          { accion: "pasoTramitacion", texto:"TR", view:false,disabled:true,styT:'color:green;font-weight:bold;font-size:1rem;',  show: 1 },
          { accion: "imp_prestos", texto: "IMP", show: 1 },
         
        ];
      // obtengo items adicionales
      // this.itemsPrestacion = this.$store.state.datos_iniciales.tipo_presup;
      // this.itemsAno = this.getItemsAno();
    },


  ver_params() {
      return {
        tipo: "fnc",
        accion: "presupuestos",
        fn_args: {
          tipo: this.tipo_detalle,
          accion: "get",
          id: this.accionRow.id,
        },
      };
  },

// CIERRES DE EXPEDIENTES-----------------------------------


// BORRADO -------------------------------------------------
 borrar() {
  // añadido temporalmente para eliminar fbi
  /*if ((this.accionRow.fra_conta_asi==0 && this.accionRow.aceptada=='1' && this.accionRow.tipo_ope=='C')) {
    this.borrar_pregunta();
    return;  
  }*/
  //--> primera linea if
  if ((this.accionRow.fra_conta_asi>0) &&
      (this.schema.ctrls.aceptada.value==1 ||
        this.schema.ctrls.bloqueada.value==1 ||
        this.schema.ctrls.fra_doc.value>'')) {
         
            // console.log("aceptada",this.schema.ctrls.aceptada.value,
            //            "bloqueada",this.schema.ctrls.bloqueada.value,
            //            "facturada",this.schema.ctrls.fra_doc.value)
            //            

           this.$root.$alert.open("DENEGADO ELIMINAR (presupuesto aceptado o bloqueado o Facturado", "error");
           return;
           }
      this.borrar_pregunta()
 },
 borrar_pregunta(){
     this.$root.$alert.open("¿Confirma Eliminar?:","confirm")
         .then((r)=>this.borrar_respuesta(r));
     },
    
borrar_respuesta(r){
      if (r) this.borrar_registro(this.accionRow.id);   
    },
//fin borrado--------------------------------

   botoneras_set() {    
     this.btnSet(1,{view:true, disabled:true})
    // // this.$store.state.G.APLICA.VERSION (1:gestion, 2:apd, 3:consulta)
    // // this.$store.state.G.G.USUARIO (usu)
    // //                  .ROL ("gestion","apds","jz","consultas")
    // //                   .ZONA (0)
      const COMPRA=this.schema.ctrls.tipo_ope.value=="C";
      const VENTA=this.schema.ctrls.tipo_ope.value=="V";
      let TRAMITACION=this.schema.ctrls.tipo_ope.value=="W";
      
      let ACEPTADA=this.schema.ctrls.aceptada.value=='1';
      let FACTURADA=this.schema.ctrls.fra_doc.value>'';
1
      let CONTABILIZADA=this.accionRow.fra_conta_asi>'0'  || (VENTA && FACTURADA);      

      let GESTION=this.app==1;
      let APDS=this.app==2;

      //departamento usuario
      let NDP=this.ndep; //$store.state.G.USUDAT.ndep);

    //----------------------------------------  
      console.log("cierre=",this.expte.record.chkconf24,this.expte.record.chkconfadmon,this.expte.record.chkconfcierre,this.schema.ctrls)

      let CIERRE=(APDS && this.expte.record.chkconf24==1); 
      if (!CIERRE) CIERRE=this.expte.record.chkconfadmon==1;      
      if (!CIERRE) CIERRE=this.expte.record.chkconfcierre==1;        
      if (!CIERRE) CIERRE=CONTABILIZADA; // || FACTURADA);                                                                    
      if (CIERRE){
         this.botones_cierre();
         return;
      }

      // APD con presupuesto bloqueado
      if (APDS &&  this.schema.ctrls.bloqueada.value==1) {
        this.botones_cierre();
        this.btnSet("bloqueo",{view:true,   disabled:false})
        return;
      } 

      //-----------------------------------------------
      // habilitar edicion + anular
      this.btnSet(2,{view:true, disabled:false});
      this.btnSet(3,{view:true, disabled:false});     

      // Ver solo si gestion y  x tipo factura
      this.btnSet("pasoFactura",{     view: GESTION && !COMPRA,  disabled: CIERRE||this.is_edit});
      this.btnSet("pasoVenta", {      view: GESTION &&  COMPRA,  disabled: CIERRE||this.is_edit});
      this.btnSet("pasoTramitacion",{ view: GESTION &&  COMPRA,  disabled: CIERRE||this.is_edit});   

      this.btnSet("acepto",{view:true, disabled:this.is_edit});
      this.btnSet("bloqueo",{view:true, disabled:this.is_edit});

      this.plantillaDisabled=!this.is_edit;
      this.btnSet("imp_prestos",{view:COMPRA, disabled: this.plantillaDisabled});
            
      //deshabilitamos
      // no edicion
      if (!this.is_edit) {                                       
          if (GESTION) {
            if (!COMPRA) {
              this.btnSet("pasoFactura",{disabled: (!ACEPTADA || FACTURADA || NDP<4)})
            } else { 
              //Venta o Tramitacion
              this.btnSet("pasoVenta",{       disabled: (!ACEPTADA)});
              this.btnSet("pasoTramitacion",{ disabled: (!ACEPTADA)});
            }
          }          
        
          if (GESTION && (VENTA || TRAMITACION || COMPRA) && CONTABILIZADA) {
              this.botones_cierre();
          }                    
      
      // edicion
      } else {             
              
        if (this.schema.ctrls.tipo_cta.value==1) {
          this.btnSet("imp_prestos", {view:COMPRA, disabled:false})
          this.plantillaDisabled=false;
        }
    }
   },

   botones_cierre(){
        this.btnSet(1,{view:true, disabled:true});
        this.btnSet(2,{view:true, disabled:true});
        this.btnSet(3,{view:true, disabled:true});        
    
        this.btnSet("pasoFactura",{view:false, disabled:true});
        this.btnSet("pasoVenta", {view:false, disabled:true});
        this.btnSet("pasoTramitacion", {view:false, disabled:true});
        this.btnSet("acepto",{view:false, disabled:true});

        this.btnSet("bloqueo",{view:false, disabled:true});
        this.btnSet("imp_prestos",{view:false, disabled:true});
        this.plantillaDisabled=true;

        //compra,empresa mixta (5949), usuarios administracion y abierto admon --- deja tramitacion
        if (this.accionRow.tipo_ope=="C" && 
            this.accionRow.tipo_cta=='1' && this.accionRow.cta_id=='5949' && 
            this.ndep>=5 &&
            this.app==1 &&
            this.expte.record.chkconfadmon==0) {

          this.btnSet("pasoTramitacion", {view:true, disabled:false});         
        }

        // añadido para control del fbi, borrar y aceptar facturas
        // +C,+S aceptadas sin contabilizar y sin pagar
        if (this.accionRow.tipo_ope=="C"  && 
            this.accionRow.aceptada=='1' && 
            this.accionRow.fra_conta_asi==0 && 
            this.app==1 &&
            Number(this.accionRow.fra_pago)== 0) {
              this.btnSet(3,{view:true, disabled:false});   
              if (this.accionRow.imp!=0) {
                this.btnSet("acepto",{view:true, disabled:false});       
              }
        }
    },

    detalle_cargado() {
      //alert("detalle_cargado exp_pres_M")
      console.log("detalle_cargado exp_pres_M");
      this.calculo_capitales();
    },

    //edicion manual (doble click)
    boton_editar() {
      this.btnSet(2,{view:true, disabled:false});
    },
    


   // pendiente: arreglado asi aqui  porque pasa dos veces por record cargado
   // y no puedo poner sw_nuevoInterno a false en records
   // probar a poner en nuevo todo lo de record_cargado
    cancelar_fin(){
       this.sw_nuevoInterno=false;
    },

    record_cargado() {
      console.log("record_Cargado")
      console.log("paso record cargado", this.estado,this.sw_nuevoInterno );
      // reseteo variables auxiliares de procesos
      this.pres_import_directos=[];
      this.plantillaValue='0';
      //
      if (this.estado!="nuevo"){
   
        this.sw_nuevoInterno=false;
        this.botoneras_set()
         if (!this.schema.ctrls.rt.value) { 
             this.ini_ivas();
         }
         return;
      }
                
     
      // valores por defecto 
      this.schema.ctrls.aceptada.value=0;
      this.schema.ctrls.bloqueada.value=0;
  
      let complemento=this.sw_nuevoInterno?this.datos_complementarios_internos:this.datos_complementarios;
      console.log("paso complememto", complemento);
      console.log("paso complememto interno", this.datos_complementarios_internos);
      this.nuevo_complemento(complemento);
   },

   //aseguramos que si suplido, pdi =1
  guardar_before() {
     //console.log("###guardar_before",this.schema.ctrls.suplido.value,this.schema.ctrls.pdi.value)

    // si venta suplido -> pdi a 0 (ejemplo: fusion solo factura suplido)
    if (this.schema.ctrls.tipo_ope=='V') {
        this.schema.ctrls.suplido.value=this.schema.ctrls.pdi.value='0';
    }

    //si +suplido pdi obligatorio
    if (this.schema.ctrls.suplido.value=='1') this.schema.ctrls.pdi.value='1';

    return true;
  },


  async nuevo_complemento(complemento){
      console.log("paso nuevo_complememto", complemento, this.expte.record);
      this.schema.ctrls.rel_tp.value="0";
      // DATOS_COMPLEMENTARIOS: tipo_op, rt, iva_gral, lineas(si hay)
      
      // Tipo compra/venta/suplido
      // cab=datos cabecera
      // lins=array de detalle
   
      let cab=complemento.cab;
      let lins=complemento.lins;
      //console.log("complememto 3", complemento);
      //console.log("lineas lins:",lins)
      //console.log("schema",this.schema.ctrls)
      //console.log("cab",cab)
      this.schema.ctrls.tipo_ope.value=cab.tipo_ope;
      this.schema.ctrls.suplido.value=cab.suplido;
    
      // cuenta pongo valores de componente manualmente
      if (cab.tipo_cta) {
        this.schema.ctrls.tipo_cta.value=cab.tipo_cta;
        if (cab.cta_id) {
          this.schema.ctrls.cta_id.value=cab.cta_id;
        } 
        
        //proveedor/cia
        let tmpRecord={tipo_cta:cab.tipo_cta, cta_id:cab.cta_id}
        let x=await this.pick_multiple().set({ctrl:this.schema.ctrls.cta_id,record:tmpRecord})
        console.log(x);
        this.schema.ctrls.cta_id.value=cab.cta_id;
        this.schema.ctrls.tipo_cta.value=cab.tipo_cta;        

        // comentado al ponerlo cuando se selecciona el proveedor (changeCuenta)
        /* if (x!==undefined) {
          if (x.pdi!==undefined) this.schema.ctrls.pdi.value=x.pdi;
        } */

      }

      this.botoneras_set();
      
      // IVA si lo ha importado lo asigna a ctrls, si no, analiza lineas para aplicar uno

      if (cab.rt && cab.iva_general) {
        this.schema.ctrls.rt.value=String(cab.rt);
        this.schema.ctrls.iva_general.value=String(cab.iva_general);          
      } else {           
        // si no es fusion y el expediente es de canarias (06206) -> igic
        if (!cab.fusion && this.expte.record.falledestloc.substr(0,5)=='06206') {
          this.ini_ivas('1');
        } else {
          // inspecciona las lineas y averigua iva
          this.ini_ivas(this.getLineasRt(lins));             
        }          
      }

      // para tramitacion
      if (cab.relacion) this.schema.ctrls.relacion.value=cab.relacion;
      if (cab.tmt1)     this.schema.ctrls.tmt1.value=cab.tmt1;

      console.log("iva final:",this.schema.ctrls.rt.value, this.schema.ctrls.iva_general.value);

      //pasar datos de array a records de detalle
             
      if (cab.tipo_ope=="V" && Array.isArray(lins) && lins.length) {
        lins=this.pasoVentaRecords(lins,cab.suplido);
      }
      this.lineas2records(lins);
       
      return;
    },
   lineas2records(lins){
       if (Array.isArray(lins) && lins.length){
          console.log("lineas lins2:",this.$store.state.stFfunes_presLin_F, lins)            
          console.log("lineas lins3:",this.$store.state[this.stName].hijo)            
          this.$store.commit(this.$store.state[this.stName].hijo + "/data2State", {prop:"records", value:[...lins]});
          
       }
       this.calculo();
     },
   pasoVentaRecords(lineas,suplido=0) {
      console.log("pasoVentaRecords 1", suplido);
      console.log("iva_general", this.schema.ctrls.iva_general.value);
      if (Array.isArray(lineas) && lineas.length)
        suplido=Number(suplido); // directo de una factura de suplido
      //let iva=Number(this.schema.ctrls.iva_general.value);
      
      // en ventas siempre el 21 o el 3  por eso tomo el primer elemento del régimen tributario
      // por si han cambiado en la cabecera el iva_general y han puesto otro porcentaje
      let iva_general=this.$store.state.datos_iniciales.rTribut[this.schema.ctrls.rt.value].tipos[0].value;

      let rt=this.rTributName;
      let iva10=[12,21,52];
      // let iva10=this.$store.state.datos_iniciales.iva10;
      //console.log("rt,iva", rt, iva10)

      //
      // map
      let recordsVenta=lineas.map(function(item){
        //console.log("pasoVentaRecords lineas ", item.imp)
          //if (item.sup=="S"){
          //    item.sup="N"
              //sustituyo maub
             // item.iva=iva
          //    item.iva=iva_general      
         // }
          item.sup="N";
          item.iva=iva_general;

          // las flores al 10%
          if (rt=="IVA" && iva10.includes(Number(item.prod_id))){
              item.iva="10.0"
          }
          // exception tipo_op SUPLIDO
          // viene de una fusion / factura de suplidos (suplido==1)
          let lineaSuplido=Number(item.suplido || 0);
          if (lineaSuplido==1 || suplido==1) {
            item.sup="S"
            item.iva="0.0"
          }
          return item;
      })
      // fin map
       console.log("Venta:", recordsVenta)
       return recordsVenta;
    },
   // ACCIONES QUE SE REALIZAN CUANDO HAY UNA MODIFICACION EN EL DETALLE
    detalle_modificado() {
      this.calculo();
    },
    calculo(){
      let records= this.miStHijo!= null? this.miStHijo.records : [];

      this.pres_calculo(
        records,
        this.schema.ctrls,
        this.schema.ctrls.tipo_ope.value);
      this.calculo_capitales();
    },

    calculo_capitales(){
     let records= this.miStHijo!= null? this.miStHijo.records : [];
     let capitales={servpm:this.expte.record.servpm,
                    servnicho:this.expte.record.servnicho,
                    servincinera:this.expte.record.servincinera,
                    servlapida:this.expte.record.servlapida,
                    servtana:this.expte.record.servtana,
                    servcomplemento:this.expte.record.servcomplemento
                    };
     this.calculo_claves(records,capitales); 
    },
    calculo_apd_manual(){
      this.schema.ctrls.tmt3.value=Number(this.schema.ctrls.apd1.value)+Number(this.schema.ctrls.apd2.value)
      this.calculo();
    },
    getLineasRt(lineas){         
      if (lineas.length==0) return "0";
      // Segun iva de lineas de detalle devuelve el  indice de rTtribut al que pertenece
      let a=[0,0,0,0]
      let tipos=this.$store.state.datos_iniciales.rTribut.map(i=>i.tipos.map((j)=>Number(j.value)));
      lineas.filter(c=>tipos.filter((t,index)=>{       
        if (t.includes(Number(c.iva))) a[index]++; 
      }));      
      //console.log("lineas analizandooo:",lineas,tipos,a);
      return  a.indexOf(Math.max(...a)).toString();
    },
    ini_ivas(rt="0"){
      console.log("ini_ivas1: ",this.schema.ctrls.rt.value)
      console.log("ini_ivas2: ",this.schema.ctrls.iva_general.value)
      this.schema.ctrls.rt.value = rt;
      this.schema.ctrls.iva_general.value = this.$store.state.datos_iniciales.rTribut[this.schema.ctrls.rt.value].tipos[0].value;
      console.log("ini_ivas: ",this.schema.ctrls.rt.value,this.schema.ctrls.iva_general.value);
    },
    //
    async get_schema_directo() {
      // Pendiente comentar cuando se utiliza esta funcion

      // ejecuto API
      let args = {
        tipo: "M",
        api: "exp_presLin_M",
        accion: "get_schema",
        fn_args: null,
      }

      console.log("----------------AWAIT SCHEMA DIRECTO ", args);
      let apiResult = JSON.parse(
        await this.$store.dispatch("ajaxRequest", { args: args })
      );
      console.log("----------------AWAIT SCHEMA DIRECTO FIN", apiResult);

      return apiResult;
    },

    async validate_particular() {
        if (Number(this.schema.ctrls.exp_id.value)>0 ) {
                let err='';
                if (Number(this.schema.ctrls.imp.value<0)) {
                     //
                     if (Number(this.schema.ctrls.rel_tp.value)==0){ 
                      err+="* DEBES MARCAR TIPO CARGO O ABONO (C/A)" 
                     }
                     //
                    if (this.schema.ctrls.relacion.value==''){
                        err+="* DEBES DE INTRODUCIR FACTURA RELACIONADA (C/A)   "	
                    }
                    //
                    if (err>"") {
                       this.$root.$alert.open(err, "error")
                       return false
                    }
                } 
        }
       //
      return true;
    },
   async apd_set(){
      if (this.schema.ctrls.tmt3.value>0){
        this.schema.ctrls.apd1.value=0
        this.schema.ctrls.apd2.value=0
        this.schema.ctrls.tmt3.value=0
        this.calculo();
        return
      }
     
    // Buscar tarifas APD
    let args={ 
        tipo:"fnc",
        accion: "mysqlProc",
        fn_args:[]
    };  

    let apd1=0;
    let apd2=0;

    //if (this.conDato(this.expte.record.apd1)) args.fn_args.push({call:"tarifaapdV",args:{apd:this.expte.record.apd1, y:0 }})
    //if (this.conDato(this.expte.record.apd2)) args.fn_args.push({call:"tarifaapdV",args:{apd:this.expte.record.apd2, y:0 }})
    let expeAno= this.expte.record.fhhr.substr(0, 4);
    let otc=(this.expte.record.callemp!='83' ? 1 : 0);
    if (this.conDato(this.expte.record.apd1)) args.fn_args.push({call:"tarifaapdV",args:{apd:this.expte.record.apd1, y:expeAno, otc: otc }})
    if (this.conDato(this.expte.record.apd2)) args.fn_args.push({call:"tarifaapdV",args:{apd:this.expte.record.apd2, y:expeAno, otc: otc }})

    console.log("resultado apd1:", args);
    //pendiente no devuelve error en sql
    // tambien devuelve 0 cuando no encuentra el apd y no []
    //if (this.conDato(this.expte.record.apd1)) args.fn_args.push({call:"tarifaapd",args:{}})
    var apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
    console.log("resultado apd3:", apiResult);
      
    if (apiResult.sql.error) {
      // alert("error en tarifas de apd")
    }


    // APD 1
    if (this.conDato(this.expte.record.apd1)){
      if( apiResult.r[0]==[] ||apiResult.r[0][0]==0 ){
        this.$root.$alert.open(
        "No se encuentra tarifa de apd1: CD"+this.expte.record.apd1,
        "error")
      } else{
        //apd1=apiResult.r[0][0]
        //apd1= this.conDato(this.expte.record.apd2)? Number(apiResult.r[0][0])/2 : apiResult.r[0][0]
        apd1= (this.conDato(this.expte.record.apd2) || this.expte.record.p_nacional=='1' || this.expte.record.p_internacional=='1') 
          ? Number(apiResult.r[0][0])/2 : Number(apiResult.r[0][0] );
        }
      }
      
      /*// APD 2
      if (this.conDato(this.expte.record.apd2)){
       if( apiResult.r[0]==[] ||apiResult.r[0][1]==0 ){
          this.$root.$alert.open(
          "No se encuentra tarifa de apd2: CD"+this.expte.record.apd2,
          "error")
        } else{
        //apd2= apiResult.r[0][1];
        apd2= Number(apiResult.r[0][1])/2;
        }
      } */
      // APD 2
      if (this.conDato(this.expte.record.apd2)) {
          // si hay apd1 o no hay apd1 tomar de apiResult.r[0][0] o bien apiResult.r[0][1]
          // comprobar que devuelve tarifa
          if( apiResult.r[0]==[] 
              || (this.conDato(this.expte.record.apd1)==false && apiResult.r[0][0]==0)
              || (this.conDato(this.expte.record.apd1)==true  && apiResult.r[0][1]==0))
          {
                  this.$root.$alert.open(
                  "No se encuentra tarifa de apd2: CD"+this.expte.record.apd2,
                  "error")

          } else{
             // si es un apd2 la tarifa siempre se divide por 2
             apd2=this.conDato(this.expte.record.apd1)?Number(apiResult.r[0][1])/2: Number(apiResult.r[0][0])/2;
        }
      }
    
      this.schema.ctrls.apd1.value=apd1;
      this.schema.ctrls.apd2.value=apd2;
      this.schema.ctrls.tmt3.value=Number(apd1)+Number(apd2);
      this.calculo();
    },
    // acciones botones----------------------------------------
   async acepto(){   
    
     this.$root.$alert.open("Elija estado:","confirm",null,["ACEPTADA","RECHAZADA"])
       .then((r)=>this.acepto_respuesta(r));
   }, 
      // let tmpAcepto=this.schema.ctrls.aceptada.value==0?"ACEPTADA"
      // this.$root.$alert
      //  if (this.schema.ctrls.aceptada.)
      //   .open("B?Desea eliminar el registro?", "confirm")
      //   .then(r => {
      //     if (r) this.borrar_registro(this.accionRow.id);
      //   });
   async acepto_respuesta(r){
     r=Number(r)+1;
       let args={ 
              tipo:"fnc",
              accion: "presupuestos",
              fn_args:{accion:"estado",
                       tipo:"fras",
                       id:this.record.id,
                       aceptada:r
                       }
              };
       console.log(args);
       await this.$store.dispatch("ajaxRequest", { args: args });
       this.btra_Mto({accion:0}) 
       return
    },

   async bloqueo(){
     if (this.app==2 && this.schema.ctrls.bloqueada.value==0 && this.schema.ctrls.aceptada.value==0){
           this.$root.$alert.open("Antes de bloquear el presupuesto debe aceptarlo o rechazarlo")
          return
     }

     if (this.app==2 && this.schema.ctrls.suplido.value==1 && this.schema.ctrls.bloqueada.value==1){
        this.$root.$alert.open(
          "Para desbloquear un Pago directo debe llamar al CTD")
          return
     }

     this.$root.$alert.open("OPCIONES DE BLOQUEO:","confirm",null,["Desbloquear","Bloquear",  "CANCELAR"])
       .then((r)=>this.bloqueo_respuesta(r));
   },
   async bloqueo_respuesta(r){
      if (r==2) return;
     let args={ 
                tipo:"fnc",
                 accion: "presupuestos",
                 fn_args:{accion:"estado",
                         tipo:"fras",
                         id:this.record.id,
                        bloqueada:r
                         }
              };
       await this.$store.dispatch("ajaxRequest", { args: args });
       this.btra_Mto({accion:0}) 
       return
  

   },
   async pasoFactura(){
      //this.$root.$alert.open("¿ Pasar a  factura ?","confirm")
        //.then((r)=>this.pasoFactura_respuesta(r));
      this.$root.$alert.open("¿ Pasar a <b>FACTURA VENTA</b> ?<br>" + 
          "<br>Se genera Numero de Factura y Fecha.<br>Despues de Aceptar se Emite Factura en PDF.<br>Recuerda que debes contabilizar el expediente.<br><br>"
          ,"confirm")
        .then((r)=>this.pasoFactura_respuesta(r)
      );      
   },
   async pasoFactura_respuesta(r){
     if (!r) return;
      let args={ 
                tipo:"fnc",
                accion: "facturas",
                fn_args:{accion:"factura_numero",
                        id:this.record.id
                        }
              };

        await this.$store.dispatch("ajaxRequest", { args: args });
        this.btra_Mto({accion:0})        
        
        this.pdf();        
    },
   async pasoTramitacion(){
      if (this.is_edit || this.schema.ctrls.tipo_ope.value!='C'){
        return;
      }
      this.$root.$alert.open("¿ Crear VENTA de TRAMITACION ?","confirm")
       .then((r)=>this.pasoTramitacion_respuesta(r));
   },
    async pasoTramitacion_respuesta(r){
      //tmp_base=elem.base;
			//tmp_relacion=elem.fra_doc;
      if (!r) return;
      let lins=[];
      let cab={tipo_ope:"V", tipo_cta:'0', cta_id:'0'};
      cab.tipo_cta=this.expte.record.callemp_tp;
      cab.cta_id=this.expte.record.callemp;
      cab.rt=this.schema.ctrls.rt.value;
      cab.iva_general=this.schema.ctrls.iva_general.value;
      // datos extra tramitacion
      cab.relacion=this.schema.ctrls.fra_doc.value;
      cab.tmt1=(Number(this.schema.ctrls.base.value)*5/100).toFixed(2);
      //
      console.log("original:",this.MD.records);
      this.sw_nuevoInterno=true;
      this.datos_complementarios_internos={cab:cab,lins:lins}
      this.btra_Mto({ accion:1 }) 
    },
   async pasoVenta(){ 
      if (this.is_edit || this.schema.ctrls.tipo_ope.value!='C'){
        return
      }
      this.$root.$alert.open("¿ Pasar a VENTA ?","confirm")
       .then((r)=>this.pasoVenta_respuesta(r));
   },
   async pasoVenta_respuesta(r){
      if (!r) return;
      console.log("original:",this.MD.records)
      // Revisar porque copia y no clona???????????????
      // cuidado asi no clona lineas=[...this.MD.records]
      // cuidado asi no clona lineas=this.MD.records.slice()
      // hacer parse y stringify
      let lins=JSON.parse(JSON.stringify(this.MD.records));
      console.log("lineas:",lins)

      // elimino maub
      //lins=this.pasoVentaRecords(lins,this.schema.ctrls.suplido.value);

      let cab={tipo_ope:"V", tipo_cta:'0', cta_id:'0'};
      cab.tipo_cta=this.expte.record.callemp_tp;
      cab.cta_id=this.expte.record.callemp;
      cab.rt=this.schema.ctrls.rt.value;
      cab.iva_general=this.schema.ctrls.iva_general.value;
      //añado maub
      cab.suplido=  this.schema.ctrls.suplido.value;

      console.log("original:",this.MD.records);
      this.sw_nuevoInterno=true;
      this.datos_complementarios_internos={cab:cab,lins:lins}
      this.btra_Mto({ accion:1 }) 
  },
  
  // Importacion de presupuestos funeraria
  imp_prestos() {
    // if (!this.is_edit) return;
    this.componente_dinamico= "funes_pres_import_F"
    this.modal= true;
    this.filtroImportServicio.cia_id=this.expte.record.callemp;
    if (this.expte.record.callemp==53258) this.filtroImportServicio.cia_id='50165';
    
    // añadida nueva linea para añadir año a importacion
    this.filtroImportServicio.fhhr=this.expte.record.fhhr;

    this.disparo_funes_pres_import= !this.disparo_funes_pres_import;
  },
  
  async  imp_prestos_extraer(evt) {
    if (evt.accion== "cerrar") this.modal=false;  
    if (evt.accion!= "extraer") return;
    this.modal=false
    // this.componente_dinamico=null;
      //

  this.pres_import_id=evt.row.id
  let args={ 
              tipo:"fnc",
              accion: "presupuestos",
              fn_args:{accion:"importar",id:this.pres_import_id, gen:0}
            };

   console.log("importar args:", args);
   let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
   //pendiente: controlar error
   console.log("importar apiResult:",apiResult)
   //
   if (apiResult.sql.error==true){
      return
   }

  //  //
  //  
  //  console.log("empaquetado:", this.schema.ctrls.presdetalle.value)
       console.log("row",evt)
       console.log("record",this.record)
       console.log("schema",this.schema.ctrls)      
    
      
       this.schema.ctrls.id_presciac.value=evt.row.id_presciac;
       this.schema.ctrls.presimp.value=evt.row.imp;
       this.schema.ctrls.presbase.value=evt.row.pnfsuma;
       this.schema.ctrls.presid.value=evt.row.id;
       this.schema.ctrls.presmunicipio.value=evt.row.municipio;
       this.schema.ctrls.presfun.value=evt.row.fun_id;

    //  -----------------------------------------------------------------------
  

      //pres_import_id:0, // guardo id de presuspuesto importado
      //pres_import_directos:[], // guardo lineas de p.externos del pres importado
      let lins=[];
      lins=[...apiResult.r[0][0],...apiResult.r[0][1] ]
      if (lins.length==0){
         return
      }
      console.log("lins", lins)
      this.schema.ctrls.presdetalle.value=this.presPackEncode(lins)
      this.lineas2records(apiResult.r[0][0]);
      // guardo la array de directos para mostrarla
      this.pres_import_directos=apiResult.r[0][1];
       //context.commit('update_records', context.rootState[context.state.padre].recordsDetalle[context.state.no_getRecordsAPI]);
      console.log("this.pres_import_directos", this.pres_import_directos)
      //error console.log("this.schema.ctrls.presdetalle.value", this.schema.ctrls.presdetalle.value)
      //
      //???? poner componete dimnamico a nullll
      return
  },
    // BOTONERA COMUN--------------------------------------------------------------------------
  async generarPresupuestos(){
  this.$root.$alert.open("¿Crear presupuestos relacionados?:","confirm")
       .then((r)=>this.generarPresupuestos_respuesta(r));
  },
  async generarPresupuestos_respuesta(){
  
  //
 
  let args={ 
              tipo:"fnc",
              accion: "presupuestos",
              fn_args:{accion:"importar",
                       id:this.pres_import_id,
                       gen:1,
                       exp_id:this.expte.record.id}
            };

   console.log("generar args:", args)
   let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
   //pendiente: controlar error
   console.log("generar apiResult:",apiResult)
   //
   if (apiResult.sql.error==true){
      return
   }
   // reseteo pres_import_directos  para que desaparezca  generar presupuestos asocioados de pantalla
  this.pres_import_directos=[];
  this.pres_import_id=0;
  // Paso records de presupuestos a expediente
  //let expte_presupuestos=apiResult.r[0][0];
  console.log("genear padre:",this.padre)
  console.log("genear padre prespuestos expediente:",apiResult.r[0])
  console.log("generar padre",this.$store.state[this.stName].padre)
  this.$store.commit(this.$store.state[this.stName].padre + "/data2State", {prop:"records", value:apiResult.r[0]});
  },
  
// funescom
//     presupuestos
//         accion: importar,
//         id: (presto),
//         gen: 0        (Generar Compras si/no)
//         exp_id: (expediente) * Solo si gen=1

// gen=0    devuelve 2 arrays con  líneas
// gen=1   devuelve array con los presupuestos del exped.

  changePlantilla(){
    if (this.plantillaValue=="0") return;
     this.$root.$alert.open("¿Importar  plantilla?:","confirm")
       .then((r)=>this.changePlantilla_respuesta(r));
  },  
  async changePlantilla_respuesta(){
  
   let args={ 
             tipo:"fnc",
            accion: "presupuestos",
            fn_args:{accion:"plantilla",
                       rt:this.schema.ctrls.rt.value,
                       id:this.plantillaValue
                        }
           };
   this.plantillaValue='0';
   let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
   console.log("plantilla:",apiResult,args)
     if (apiResult.sql.error==true){
      return
   }
    this.lineas2records(apiResult.r[0]);

  //  console.log("generar args:", args)
  //  let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
  //  //pendiente: controlar error
  //  console.log("generar apiResult:",apiResult)
  //  //
  //  if (apiResult.sql.error==true){
  //    , return

  //      this.lineas2records(apiResult.r[0][0]);


  },

  changeCuenta(evt) {

    console.log('**changeCuenta',evt);

    let tp=this.schema.ctrls.tipo_cta.value;
    //let cta=this.schema.ctrls.cta_id.value;
    let sup=this.schema.ctrls.suplido.value;
    let pdi=this.schema.ctrls.pdi.value;

    //misc y ayuntamiento pasamos a +S
    if (tp=='3' && evt.tipo=='AYUNTAMIENTO' && sup=='0') {      
      this.cambio_tipo_factura();
      this.$root.$alert.open('Factura ha sido convertida a +SUPLIDO', 'success', 1800);
      return;
    }
    
    //pdi
    if (sup=='0' && evt.pdi!=pdi) {
      this.schema.ctrls.pdi.value=evt.pdi;
    }

  },

  changeTipoCargoAbono() {        
    let tipo=this.schema.ctrls.rel_tp.value;
    if ((tipo!='2' && tipo!='3')) return;
    
    //pasamos todo a negativo

    let hijo = this.$store.state[this.stName].hijo;
    let records=JSON.parse(JSON.stringify(this.$store.state[hijo].records));

    records.forEach(item => { 
        if (item.und>0) item.und=-item.und;
        if (item.pvp<0) item.pvp=-item.pvp;     
        item.imp=this.round(item.und*item.pvp);   
    });
    this.$store.commit('data2State', { modulo:hijo, prop:'records', value:records});
    this.calculo();

  },

  cambio_tipo() {
    if (this.accionRow.tipo_ope=='V') return;

    let a=(this.accionRow.suplido=='1' ? 'COMPRA' : 'SUPLIDO');
    
    this.$root.$alert.open('Cambiamos FACTURA seleccionada a <b> ' + a + '</b> ?<br>Acuerdate de revisar todas las lineas con sus conceptos e importes/suplidos.<br><br>', 'confirm')
    .then(r=> { if (r) this.cambio_tipo_factura(); });
  },

  //
  async cambio_tipo_factura() {
    let ss=(this.schema.ctrls.suplido.value=='1' ? 0 : 1);
    
    this.schema.ctrls.suplido.value=ss;
    this.schema.ctrls.pdi.value=ss;

    this.cambio_tipo_lineas(ss);

  },

  cambio_tipo_lineas(ss) {    
    let hijo = this.$store.state[this.stName].hijo;
    let records=JSON.parse(JSON.stringify(this.$store.state[hijo].records));
    console.log("****",ss,records)
    records.forEach(item => { 
        if (ss==1 && item.sup!='S') item.sup='S';
        if (ss==1 && item.sup=='S') item.iva=0;        
    });
    this.$store.commit('data2State', { modulo:hijo, prop:'records', value:records});
    this.calculo();
  },
 

  async pdf(){    
    if (this.record.id==0) return;
    let args = { tipo: 'fnc', accion: 'expediente', fn_args: { accion: "factura", id: this.record.id, opc: 1 }};         
    console.log('args pdf: ', args);
    let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
    console.log('apiResult pdf: ', apiResult);
    if (apiResult.sql.error) {
      this.$root.$alert.open(apiResult.sql.msg, 'error');
      return;
    }

    // abro documento
    window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank");    
     
  },

  
  copy(){    
    const hijo=this.$store.state[this.stName].hijo
    const records=JSON.parse(JSON.stringify(this.$store.state[hijo].records));
    if (records.length==0){
      this.$root.$alert.open('No hay datos para copiar', 'error')
      return
    }
    this.$store.commit('data2State', { modulo:'datos_iniciales', prop:'recordsCopy', value:records});
    this.$root.$alert.open('Datos copiados', 'info')
  },
  pega(){
    
    const hijo=this.$store.state[this.stName].hijo
    const records=JSON.parse(JSON.stringify(this.$store.state.datos_iniciales.recordsCopy));

    console.log("records:",records)
    if (records.length==0){
      this.$root.$alert.open('No hay datos para pegar', 'error')
      return
    }

    if (records.length>0 && this.is_edit) {
        this.$store.commit('data2State', { modulo:hijo, prop:'records', value:records});
        
        if (this.schema.ctrls.suplido.value=='1') {
          this.cambio_tipo_lineas(this.schema.ctrls.suplido.value);
          
        }else {
          this.recalcularIva(this.schema.ctrls.iva_general.value);
        }
        
        this.$root.$alert.open('Datos pegados', 'info')
    }

  },

  
  //
  changeRt(val) {
    let ivaStr=this.$store.state.datos_iniciales.rTribut[val].tipos[0].value;
    this.recalcularIva(ivaStr);
  },


  changeIvaGeneral(val) {
    this.recalcularIva(val);
  },

  recalcularIva(iva) {
    this.schema.ctrls.iva_general.value= iva;
    let hijo = this.$store.state[this.stName].hijo;
    let records=JSON.parse(JSON.stringify(this.$store.state[hijo].records));
    
    records.forEach(item => { 
                        if(item.sup=='N') item.iva=iva;
                        if (this.rTributName=="IVA" && this.iva10.includes(Number(item.prod_id))){
                             item.iva="10.0"
                         }
    });
    this.$store.commit('data2State', { modulo:hijo, prop:'records', value:records});
    this.calculo();
  },

  // override mixinMD
 /*  async nuevo() {   
    // compruebo si hay mtos auxialiares en edición     
    mixinMD.methods.nuevo();
  }, */
  //------------------------------------------------------------------------------------------------
 //
  // PENDIENTE: meter en mixinctrls
  conDato(i) {
      if (i == "0" || i == "" || typeof i === "undefined" || i == null) return false;   
      return true;
    },
  },

  computed: {
    expte(){
      
      if (this.auxRecord) {
        return this.auxRecord;
      }

      if (!this.miStAbuelo){
         alert("expte computed: Faltan datos expediente");
         return null
      }
      console.log(this.miStAbuelo.record.fallename)
      return this.miStAbuelo;
    },

    tipo_ope(){
      let tipo=this.schema.ctrls.tipo_ope.value;
      if (this.schema.ctrls.suplido.value==1) tipo="SUP";
      let obj=this.itemsTipo_ope.find(item =>item.tipo_ope==tipo);
      return obj?obj:this.itemsTipo_ope[0];
    },

    apd_computed:{
      get: function() {
           return this.schema.ctrls.tmt3.value>0?true:false
      },
      set: function() {
            this.apd_set()
             return 
      }
    },
 
    is_mxEdit() {
      console.log("entrando en is_mxedit");

      var hijo = this.$store.state[this.stName].hijo;
      if (!hijo) return false;
      console.log("hijo:", hijo);
      var nieto = this.$store.state[hijo].hijo;
      console.log("nieto:", nieto);
      if (!nieto) return false;
      return this.$store.state[nieto].estado === "editar" ||
        this.$store.state[nieto].estado === "nuevo"
        ? true
        : false;
    },

    get_ivas() {      
      if (!this.$store.state.datos_iniciales) return [];
      if (this.schema.ctrls.rt.value) {                 
        return this.$store.state.datos_iniciales.rTribut[Number(this.schema.ctrls.rt.value)].tipos;
      }

       return[];
    },
    

    rTributName(){
     if (this.schema.ctrls.rt.value!='') {
       return this.$store.state.datos_iniciales.rTribut[Number(this.schema.ctrls.rt.value)].name
     }
     return ''
    },
     miStAbuelo() {
      if (!this.miStPadre) return null;
      if (!this.miStPadre.padre) return null;
      return this.$store.state[this.miStPadre.padre];
    },
  
  
  },


  //
  watch: {
    miStHijo() {
      console.log('HIJO WATCH::: ', this.miStHijo);
      if (!this.miStHijo) return;
      if (this.miStHijo.name!= this.stNameHijo) {
        this.$root.$alert.open('Llame a informática al teléfono 666564931 indicando lo siguiente: exp_pres_M HIJO= ' + this.miStHijo.name, 'info');
        //this.$store.commit('data2State', { modulo:this.stName, prop:'hijo', value:this.stNameHijo});
      }
    }
  }
};
</script>
<style scoped>
.v-input--selection-controls {
  margin-top: 0;
}
.select {
  /* outline: 1px solid red; */
  border: 1px solid rgb(94, 114, 134, 0.60);
  border-radius: 18px 18px 18px 18px;
  font: 1rem;
  width: 200px;
  height: 25px;
  outline: none;

  margin-right: 2px;
  margin-top:4px;
  text-align-last: center;
  
  /* background-color: rgb(236, 182, 157); */
   /* background-color: rgb(10, 173, 179); */
  background-image: none;
  cursor: pointer;
  /* background: url(assets/selectArrow.png) no-repeat right #BADA55; */
  /* clip-path: polygon(100% 0%, 0 0%, 50% 100%); */
}
</style>
