export var mixinMD = {
  computed: {
    M() {
      if (!this.stName) return null;
      return this.$store.state[this.stName];
    },

    MD() {      
      if (!this.$store.state[this.stName].hijo) return null;
      return this.$store.state[this.$store.state[this.stName].hijo];
    },
   
    MX() {
      if (!this.miStHijo) return null;
      if (!this.miStHijo.hijo) return null;
      return this.$store.state[this.miStHijo.hijo];     
    },
  },

  methods: {

    // OVERRIDE DE FUNCIONES--------------------------------------------    
    // 
    records_cargados_fin(){},
    // 
    detalle_modificado_fin(){},    
    //-----------------------------------------------------------------
    

    // 
    async nuevo() {   
  
      // compruebo si hay mtos auxialiares en edición      
      if (!this.mtos_auxiliares_estado()) return;
     
   

      await this.record2ctrl({}, this.schema.ctrls, true);      
      this.$store.dispatch(this.stName + '/nuevoDetalle');

      //
      this.disparoFinder= !this.disparoFinder;
           
      // fn que ejecuta al cargar el record del Mto
  
      //this.record_cargado(); 
    },

       
    // GUARDAR EDITAR
    async guardar_editar() {
      // compruebo si ha habido cambios tanto en cabecera como en lineas
      // si no hay, recargo Mto
      var changes= this.compare_recordMD();
      if (!changes) return this.btra_Mto({ accion:0 });
      
      // llamo a la API para guardar los datos
      var apiResult= await this.guardar_sql(this.accionRow.id);
                
      // si hay un error en la grabación, no continuo      
      if (apiResult.sql.error== true) {       
        this.$root.$alert.open(apiResult.sql.msg, 'error');
        return false;
      }
      
      // recargo Mto
      this.btra_Mto({ accion:0 });
    },  


    // GUARDAR NUEVO
    async guardar_nuevo() {    
      // llamo a la API para guardar los datos
      var apiResult= await this.guardar_sql();

      // si hay un error en la grabación, no continuo
      if (apiResult.sql.error== true) {       
        this.$root.$alert.open(apiResult.sql.msg, 'error');
        return false;
      }
            
      // actualizo ID y recargo Mto
      this.accionRow.id= apiResult.r;
      this.btra_Mto({ accion:0 });  
    },

    
    // monto objeto con los datos a guardar de cabecera y lineas,
    // ejecuto la gabación y muestro mensaje de la operación 
    async guardar_sql(id) {   
      // compruebo si hay mtos auxialiares en edición      
      if (!this.mtos_auxiliares_estado()) return { sql:{ error:true, msg:'Existe un mantenimiento auxiliar en edición' }};

      // compruebo si hay incongruencias en las lineas del Grid
      if (!this.check_datosLineas()) return { sql:{ error:true, msg:'Error de comprobación de lineas' }};
            
      //
      var datos_cab = this.recopilo_datosCab();
      var datos_lineas = await this.recopilo_datosLineas();
      var cabylineas = this.mount_cabylineas(datos_cab, datos_lineas, id);

      // guardo datos  
      var args = {
        tipo: "fnc",        
        accion: "presupuestos",
        fn_args: cabylineas
      };
      
      var apiResult= await this.$store.dispatch("ajaxRequest", { args: args });  
      console.log("DEV " + this.Entorno.api + " resultado guardar_sql ********** MIXINMD", apiResult);

      // muestro mensaje del resultado de la grabación
      this.guardar_mensaje(apiResult);
      return apiResult;
    },


    // muestro mensaje del resultado de la grabación
    async guardar_mensaje(apiResult) {            
      if (apiResult.sql.error== true) {          
        this.$root.$alert.open(apiResult.sql.msg, "error");
        return;
      }

      this.$root.$alert.open("Registro guardado correctamente!", "success", 1800);       
    },        


    // al cancelar la edición de un registro comprueb si hay cambios tanto
    // en el detalle como en el maestro.
    async cancelar_editar() {      
      // compruebo si hay cambios en un Finder Detalle
      if (this.miStHijo) {
        if (this.miStHijo.hijoChanges) {
          this.cancelar_pregunta();
          return;
        }
      }

      // compruebo si hay cambios en el Maestro
      if (Object.keys(this.compare_record(this.schema.ctrls, this.record)).length> 0) {
        this.cancelar_pregunta();
        return;
      }

      // NO hay cambios. Recargo datos
      this.btra_Mto({ accion:0 });      
    },   
        

    // BORRAR
    async borrar() {
      var self = this;
      this.$root.$alert
        .open("¿Desea eliminar cabecera y lineas?", "confirm")
        .then(async function(success) {
          if (!success) return;

          // llamo a la API para eliminar la cabecer y las lineas
          var apiResult= await self.borrar_sql();

          // si hay un error en la eliminación, no continuo
          if (apiResult.sql.error== true) return;

          // cierro ventana de Mto
          self.$emit("onEvent", { accion: 6 });
        });
    },


    // obtengo objeto con los datos para eliminar los registros de cabecera y lineas,
    // ejecuto la eliminación y muestro mensaje de la operación 
    async borrar_sql() {      
      var cabylineas = this.mount_cabylineas({}, {}, this.accionRow.id);

      // pongo el id en negativo
      cabylineas.id = -cabylineas.id;

      // elimino datos  
      var args = {
        tipo: "fnc",        
        accion: "presupuestos",
        fn_args: cabylineas
      };
      

      var apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
      console.log("DEV " + this.Entorno.api + " resultado borrar_sql ********** MIXINMD", apiResult);

      // muestro mensaje del resultado de la grabación
      this.borrar_mensaje(apiResult);
      return apiResult;
    },

    // muestro mensaje del resultado de la eliminación
    async borrar_mensaje(apiResult) {            
      if (apiResult.sql.error== true) {          
        this.$root.$alert.open(apiResult.sql.msg, "error");
        return;
      }

      this.$root.$alert.open("Registro eliminado correctamente!", "success");       
    },    


    // devuelvo datos de cabecera
    recopilo_datosCab() {
      // guardo id del padre
      if (this.estado === 'nuevo') {        
        if (this.miStAbuelo!= null && this.auxRecord== null) {
          this.schema.ctrls[this.campo_relacionado].value= this.miStAbuelo.schema.ctrls['id'].value;
        }
        
        // en caso de recibir como propiedad un record auxiliar (auxRecord), meto su id como campo relacionado
        if (this.auxRecord!= null){
          this.schema.ctrls[this.campo_relacionado].value= this.auxRecord.record.id;
        }

        console.log('chema: ', this.schema.ctrls, this.auxRecord);

      }

      // paso datos de los controles al record
      //return this.ctrl2record(this.schema.ctrls, this.record);
      //return this.par_ctrls_valor(this.schema);
      return this.ctrl2record_wo_compare(this.schema.ctrls);
    },


    // devuelvo datos de lineas de detalle
    async recopilo_datosLineas() {      
      var hijo= this.$store.state[this.stName].hijo;
      if (!hijo) return [];

      var records= this.$store.state[hijo].records;
      var nieto= this.$store.state[hijo].hijo;
      var schema;
      
      if (!nieto) {     
        // cargo schema nieto        
        schema= await this.get_schema_directo();        

      }else {
        schema= this.$store.state[nieto].schema;
      }

      var record= {};
      var lineas_records= [];
      
      // filtro datos de los records según el schema
      for (var i=0; i< records.length; i++) {
        Object.keys(schema.ctrls).forEach(key => {
          if (schema.ctrls[key].nosave == true) return;
          if (!schema.ctrls[key].f) return;
          if (typeof records[i][schema.ctrls[key].f]== undefined) return;
          record[schema.ctrls[key].f] = records[i][schema.ctrls[key].f];
        });

        lineas_records.push(record);
        record= {};
      }
      
      return lineas_records;
    },
      

    // obtengo si hay cambios en los datos de cabecera y lineas
    compare_recordMD() {
      var hijo= this.$store.state[this.stName].hijo;

      if (hijo) {
        if (this.$store.state[hijo].hijoChanges) return true;
      }

      if (Object.keys(this.compare_record(this.schema.ctrls, this.record)).length > 0) return true;
      return false;
    },


    // monto objeto con los datos de cabecera y lineas que mandamos a la API
    mount_cabylineas(cab= {}, lineas= {}, id= 0) {   
      var cabylineas = {
        tipo: this.tipo_detalle,
        accion:'set',
        id: id,                  
        cab: cab,
        lin: lineas
      };
      
      console.log("-------- Objeto cabylineas : ", cabylineas);
      return cabylineas;
    },


    // fn a ejecutar al cargar los registros del Finder
    /* records_cargados(data) { 
      console.log("DEV " + this.Entorno.api + " records_cargados **********", data);

      // guardo records y schema detalle
      this.records_detalle = data.records;
      this.schema_detalle = data.schema;

      // indico que no hay cambios en las lineas de detalle
      this.lineas_actualizadas = false;

      //
      this.records_cargados_fin(data);
    },
 */

    // fn a ejecutar al modificar o crear una nueva linea de detalle
    /* detalle_modificado(data) {
      console.log("DEV " + this.Entorno.api + " detalle_modificado **********", data);

      // guardo records y schema detalle
      this.records_detalle = data.records;
      this.schema_detalle = data.schema;

      // indico que hemos modificado una linea del detalle
      this.lineas_actualizadas = true;

      //
      this.detalle_modificado_fin(data);    
    }, */


  },

};
